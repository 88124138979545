var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _c("div", { staticClass: "container" }, [
      _c("h3", [_vm._v("プライバシーポリシー")]),
      _c("p", { staticClass: "my-4" }),
      _c("div", [
        _vm._v(
          "株式会社 ロビンソン（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", [
        _vm._v(
          "「個人情報」とは、個人情報の保護に関する法律（以下、「個人情報保護法」といいます）第２条第１項に定める「個人情報」を指すものとし、本個人情報保護方針を定め、その遵守の徹底を図ることといたします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第1条（個人情報の取得）")]),
      _c("div", [
        _vm._v(
          "当社は、ユーザーがユーザー登録をする際にメールアドレス、決済処理に関連する情報、その他必要な個人情報をお尋ねすることがあります。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第2条（個人情報を収集・利用する目的）")
      ]),
      _c("div", [
        _vm._v("当社が個人情報を収集・利用する目的は、以下のとおりです。")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・当社サービスの提供・運営のため")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "・ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）"
        )
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "・ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため"
        )
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・メンテナンス、重要なお知らせなど必要に応じたご連絡のため")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "・利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため"
        )
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "・ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため"
        )
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・有料プランにおいて、ユーザーに利用料金を請求するため")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・上記の利用目的に付随する目的")
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第3条（利用目的の変更）")]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本サービス上に公表するものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第4条（個人情報の第三者提供）")
      ]),
      _c("div", [
        _vm._v(
          "当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [_vm._v("1.法令に基づくとき")]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "4.国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "5.予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき"
        )
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・利用目的に第三者への提供を含むこと")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・第三者に提供されるデータの項目")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・第三者への提供の手段または方法")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・本人の求めに応じて個人情報の第三者への提供を停止すること")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("・本人の求めを受け付ける方法")
      ]),
      _c("div", [
        _vm._v(
          "前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "(1)当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "(2)合併その他の事由による事業の承継に伴って個人情報が提供される場合"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "(3)個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第5条（個人情報の開示）")]),
      _c("div", [
        _vm._v(
          "ユーザーがユーザーご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第6条（個人情報の訂正および削除）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第7条（個人情報の利用停止等）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "4.前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第8条（プライバシーポリシーの変更）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本サービスに掲載したときから効力を生じるものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第9条（クッキー（Cookie））")
      ]),
      _c("div", [
        _vm._v(
          "本サービスでは、Cookie（クッキー）を利用しています。Cookieとは、本サービスにアクセスした際にブラウザに保存される情報ですが、お名前やメールアドレス等の個人情報は含まれません。"
        )
      ]),
      _c("div", [
        _vm._v("アクセス解析にCookieの情報を利用させていただく場合があります。")
      ]),
      _c("div", [
        _vm._v(
          "ブラウザの設定により、Cookieを使用しないようにすることも可能です。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第10条(その他、アプリケーションプライバシーポリシー)")
      ]),
      _c("div", [
        _vm._v(
          "本サービスでは、登録ユーザが利用可能な機能において位置情報を取得する場合がありますが、取得した位置情報の利用目的は本サービスの機能限定とし、本サービスと関連のない利用者情報の収集の目的としては使用しません。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第10条（お問い合わせ窓口）")
      ]),
      _c(
        "div",
        [
          _vm._v("本ポリシーに関するお問い合わせは、"),
          _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
            _vm._v("こちらの窓口")
          ]),
          _vm._v("よりお願い致します。")
        ],
        1
      ),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", [_vm._v("制定：2020年5月31日")]),
      _c("div", { staticClass: "text-right" }, [_vm._v("以上")]),
      _c("p")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }